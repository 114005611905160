import { useState } from 'react';
import DetailedBackHeader from 'src/components/atoms/DetailedBackHeader/DetailedBackHeader';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import MatchingDescription from 'src/components/organisms/MatchingDescription/MatchingDescription';
import SelectMatchingCandidates from 'src/components/organisms/SelectMatchingCandidates/SelectMatchingCandidates';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import styles from './MatchCandidatesTemplate.module.scss';
import MatchingKeywords from 'src/components/organisms/MatchingKeywords/MatchingKeywords';

interface IMatchCandidatesTemplateProps {
  requisition: IRequisition | null;
}

const MatchCandidatesTemplate = ({ requisition }: IMatchCandidatesTemplateProps) => {
  const [jobDescription, setJobDescription] = useState<string>(requisition?.description || '');
  const [keywords, setKeywords] = useState<string>('');

  return (
    <main className={styles.container}>
      <AppHeader currentPage={'AI_MATCHING'} />
      <section className={styles.matchCandidateCard} data-test-id='match-candidate-card'>
        <DetailedBackHeader
          requisition={requisition}
          backUrl={'/ai-matching'}
          ownClass={styles.backHeader}
        />
        <aside className={styles.jobDescription} data-test-id='aside-requisition-info'>
          <MatchingDescription
            jobDescription={requisition ? jobDescription : ''}
            setJobDescription={setJobDescription}
          />
          <MatchingKeywords
            keywords={keywords}
            setKeywords = {setKeywords}
          />
        </aside>
        <SelectMatchingCandidates
          jobDescription={jobDescription || ''}
          keywords={keywords || ''}
          type={requisition?.jobTitle || 'New Match'}
        />
      </section>
    </main>
  );
};

export default MatchCandidatesTemplate;
