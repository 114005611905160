import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import type { TPageType } from 'src/components/organisms/AppHeader/AppHeader';
import { translate } from 'src/contexts/Language';
import { resetPagination } from 'src/store/pagination/paginationSlice';
import { useAppDispatch } from 'src/store/reduxHooks';
import styles from './AppHeaderNav.module.scss';

interface IAppHeaderNavProps {
  currentPage: TPageType;
}

const AppHeaderNav = ({ currentPage }: IAppHeaderNavProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <nav className={styles.nav} data-test-id='application-header-nav'>
      <NavLink
        className={classNames(styles.link, currentPage === 'AI_MATCHING' && styles.activeTab)}
        to='/ai-matching'
        data-test-id='ai-matching-nav-link'
        onClick={(e) => {
          e.preventDefault();
          dispatch(resetPagination());
          navigate('/ai-matching');
        }}
      >
        {translate('aiMatching')}
      </NavLink>

    </nav>
  );
};

export default AppHeaderNav;
