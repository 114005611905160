import { useEffect, useState } from 'react';
import type { IInfoCardData } from 'src/components/molecules/InfoCard/InfoCard';
import AIMatchingPageTemplate from 'src/components/templates/AIMatchingPageTemplate/AIMatchingPageTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';
import { prepareAIMatchingPageData } from 'src/pages/AIMatchingPage/prepareAIMatchingPageData';
import { useAppDispatch, useAppSelector } from 'src/store/reduxHooks';
import { requestWrapper } from 'src/utils/requestWrapper';
import { setRequisitions } from 'src/store/requisitions/requisitionsSlice';
import { prepareRequisitionsData } from 'src/pages/RequisitionsPage/prepareRequisitionsPageData';


const AIMatchingPage = () => {
  const [infoCardData, setInfoCardData] = useState<IInfoCardData | null>(null);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const { page, search, tab } = useAppSelector((state) => state.pagination);
  const dispatch = useAppDispatch();
  useADAccessToken();

  useEffect(() => {
    setInfoCardData(prepareAIMatchingPageData(0));
    setIsPageLoading(true);
    requestWrapper('GET', '/jobs', 
      {
        searchPhrase: search
      }
    )
      .then((response) => {
        dispatch(setRequisitions(prepareRequisitionsData(response)));
        setInfoCardData(prepareAIMatchingPageData(response.length));
        setIsPageLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsPageLoading(false);
      });
  }, [dispatch, page, search, tab]);

  return <AIMatchingPageTemplate infoCard={infoCardData} isPageLoading={isPageLoading} />;
};

export default AIMatchingPage;
