import type {
  IAICandidate,
  IAIModel,
} from 'src/components/organisms/SelectMatchingCandidates/SelectMatchingCandidates';
import styles from './CandidatesList.module.scss';
import { getUUID } from 'src/utils/getUUID';
import Select from 'react-select';
import CandidatesSearch from 'src/components/organisms/SelectMatchingCandidates/components/CandidatesSearch/CandidatesSearch';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import AvengaLogo from 'src/components/atoms/AvengaLogo/AvengaLogo';

interface ICandidatesListProps {
  candidatesList: IAICandidate[];
  AIModelsList: IAIModel[];
  chosenModel: IAIModel | undefined;
  setChosenModel: (chosenModel: IAIModel) => void;
  setSearchCandidateName: (searchCandidateName: string) => void;
  searchCandidateName: string;
  handleShortlistedChange: (id: string) => void;
  setChosenCandidate: (candidate: IAICandidate) => void;
  handleShowMoreCandidates: () => void;
  isLoading: boolean;
  jobDescription: string;
}

const CandidatesList = ({
  candidatesList,
  chosenModel,
  setChosenModel,
  AIModelsList,
  searchCandidateName,
  setSearchCandidateName,
  handleShortlistedChange,
  setChosenCandidate,
  handleShowMoreCandidates,
  isLoading,
  jobDescription,
}: ICandidatesListProps) => {
  const [showShortlisted, setShowShortlisted] = useState(false);

  useEffect(() => {
    setShowShortlisted(false);
  }, [jobDescription, chosenModel]);

  const candidates = showShortlisted
    ? candidatesList.filter((candidate) => candidate.isShortlisted === true)
    : candidatesList;

  return (
    <div className={styles.candidatesContainer}>
      <div className={styles.header}>
        <h2>Candidates</h2>
        <div className={styles.searchForm}>
          {/* <label className={styles.selectContainer} htmlFor='AIModel'>
            AI Model
            <Select
              className={styles.select}
              options={AIModelsList.map((model) => ({
                value: model.id,
                label: `${model.type} (version ${model.version})`,
              }))}
              value={{
                value: chosenModel?.id || '',
                label: chosenModel ? `${chosenModel?.type} (version ${chosenModel?.version})` : '',
              }}
              onChange={(selectedOption) => {
                setChosenModel(
                  AIModelsList.find((model) => model.id === selectedOption?.value) as IAIModel,
                );
              }}
              id='AIModel'
            />
          </label> */}
          <CandidatesSearch
            searchCandidateName={searchCandidateName}
            setSearchCandidateName={setSearchCandidateName}
          />
        </div>
      </div>
      <div className={styles.candidatesListContainer}>
        <div className={styles.tabs}>
          <button
            className={classNames(styles.tab, !showShortlisted && styles.activeTab)}
            onClick={() => {
              setShowShortlisted(false);
            }}
          >
            All Candidates
          </button>
          <button
            className={classNames(styles.tab, showShortlisted && styles.activeTab)}
            onClick={() => {
              setShowShortlisted(true);
            }}
          >
            Shortlist
          </button>
        </div>
        <ul className={styles.candidatesList}>
          {candidates.map((candidate, index) => (
            <li key={candidate.id} className={styles.candidateItem}>
              <span className={styles.order}>{index + 1}.</span>
              <p
                onClick={() => {
                  setChosenCandidate(candidate);
                }}
                className={styles.candidateName}
              >
                {candidate.name}
              </p>
              <span className={styles.avengaEmployee}>{candidate.isAvengaEmployee && <AvengaLogo />}</span>
              <span className={styles.dateAdded}>{candidate.dateAdded}</span>
              <span className={styles.score}>{candidate.score} %</span>
              <button
                onClick={() => {
                  handleShortlistedChange(candidate.id);
                }}
                className={styles.btn}
              >
                {candidate.isShortlisted ? (
                  <Icon icon={IconType.HeartFull} />
                ) : (
                  <Icon icon={IconType.HeartOutline} />
                )}
              </button>
              <button className={styles.btn}>
                <Icon icon={IconType.MorePurple} />
              </button>
            </li>
          ))}
        </ul>
        {isLoading && <LoadingSpinner />}
        {!isLoading && !showShortlisted && (
          <button onClick={handleShowMoreCandidates} className={styles.showMoreCandidates}>
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

export default CandidatesList;
