import { useState } from 'react';
import styles from './SearchInput.module.scss';

import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import { useAppDispatch } from 'src/store/reduxHooks';
import { changeSearch } from 'src/store/pagination/paginationSlice';

interface ISearchInput {
  type: 'REQUISITIONS' | 'RECRUITMENT' | 'AI_MATCHING';
}

const SearchInput = ({ type }: ISearchInput) => {
  const [searchText, setSearchText] = useState<string>('');
  const dispatch = useAppDispatch();

  return (
    <div className={styles.search}>
      <input
        data-test-id='search-input'
        className={styles.searchInput}
        placeholder={translate(
          type === 'RECRUITMENT'
            ? 'searchReruitmentCardPlaceholder'
            : 'searchRequisitionsCardPlaceholder',
        )}
        value={searchText}
        onChange={(event) => {
          setSearchText(event?.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter')
          {
            dispatch(changeSearch(searchText));
          }
        }}
      />
      <Icon icon={IconType.Search} />
    </div>
  );
};

export default SearchInput;
