import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Alert from 'src/components/atoms/Alert/Alert';
import PrivateRoute from 'src/components/utility/PrivateRoute/PrivateRoute';
import { AlertProvider } from 'src/contexts/AlertContext';
import useScrollToTop from 'src/hooks/useScrollToTop';
import AIMatchingPage from 'src/pages/AIMatchingPage/AIMatchingPage';
import MatchCandidatePage from 'src/pages/AIMatchingPage/sub-pages/MatchCandidatePage/MatchCandidatePage';
import NotFoundPage from 'src/pages/ErrorPages/NotFoundPage';
import LoginPage from 'src/pages/Login/LoginPage';
import SettingsPage from 'src/pages/SettingsPage/SettingsPage';
import { history } from 'src/utils/history';

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  useScrollToTop();

  return (
    <AlertProvider>
      <Alert />
      <Routes>
        <Route path='/' element={<PrivateRoute component={<AIMatchingPage />} />} />
        <Route path='/login' element={<LoginPage />} />

        <Route path='/ai-matching' element={<PrivateRoute component={<AIMatchingPage />} />} />
        <Route
          path='/match-candidate'
          element={<PrivateRoute component={<MatchCandidatePage />} />}
        />

        <Route path='/settings' element={<PrivateRoute component={<SettingsPage />} />} />

        <Route path='*' element={<PrivateRoute component={<NotFoundPage />} />} />
      </Routes>
    </AlertProvider>
  );
}

export default App;
