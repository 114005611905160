import type { IInfoCardData } from 'src/components/molecules/InfoCard/InfoCard';
import type {
  IRequisition,
  IRequisitionResponseData,
} from 'src/pages/RequisitionsPage/Requisitions.def';

export const prepareRequisitionsInfoCardData = (
  totalDataCount: number | null,
  lastUpdateDate?: Date,
): IInfoCardData | null => ({
  nameTranslationId: 'requisitions',
  totalCount: totalDataCount || 0,
  type: 'REQUISITIONS',
  tabs: [
    { nameTranslationId: 'allRequisitions', type: 'all' },
    { nameTranslationId: 'pending', type: 'pending' },
    { nameTranslationId: 'active', type: 'active' },
    { nameTranslationId: 'actionRequired', type: 'action_required' },
    { nameTranslationId: 'canceled', type: 'canceled' },
  ],
  lastUpdateDate: lastUpdateDate,
});

export const prepareRequisitionsData = (
  requisitionsResponse: IRequisitionResponseData[] | null,
): IRequisition[] => {
  if (!requisitionsResponse) {
    return [];
  }

  return requisitionsResponse.map((requisition: IRequisitionResponseData) => ({
    jobOrderId: requisition.jobOrderId,
    jobTitle: requisition.jobTitle,
    description: requisition.description,
    skillsRequired: requisition.skillsRequired,
    candidatesAddress: requisition.candidatesAddress,
    clientCompany: requisition.clientCompany,
    contactPersonEmail: requisition.contactPersonEmail,
    contactPerson: requisition.contactPerson,
    candidatesList: [],
    expanded: false,
  }));
};
