import type { IAICandidate } from 'src/components/organisms/SelectMatchingCandidates/SelectMatchingCandidates';
import styles from './CandidateDetails.module.scss';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import classNames from 'classnames';

interface ICandidateDetailsProps {
  candidatesList: IAICandidate[];
  candidate: IAICandidate;
  setChosenCandidate: (candidate: IAICandidate | null) => void;
  handleShortlistedChange: (id: string) => void;
  type: string;
}

const CandidateDetails = ({
  candidatesList,
  candidate,
  setChosenCandidate,
  handleShortlistedChange,
  type,
}: ICandidateDetailsProps) => {

  const handlePreviousCandidate = () => {
    if (candidatePosition === 1) return;

    setChosenCandidate(
      candidatesList[candidatesList.findIndex((cand) => cand.id === candidate.id) - 1],
    );
  };

  const handleNextCandidate = () => {
    if (candidatePosition === candidatesList.length) return;

    setChosenCandidate(
      candidatesList[candidatesList.findIndex((cand) => cand.id === candidate.id) + 1],
    );
  };

  const candidatePosition = candidatesList.findIndex((cand) => cand.id === candidate.id) + 1;

  return (
    <div className={styles.candidateDetailsContainer}>
      <div className={styles.asideHeader}>
        <p
          className={styles.backTitle}
          onClick={() => {
            setChosenCandidate(null);
          }}
        >
          <Icon icon={IconType.ArrowDownBlack} /> Back to candidates list
        </p>
        <button className={styles.btn}>
          <Icon icon={IconType.MorePurple} />
        </button>
      </div>
      <div className={styles.header}>
        <div
          className={classNames(styles.changeCandidateBtn, styles.previousCandidate)}
          onClick={handlePreviousCandidate}
        >
          <Icon icon={IconType.ArrowDownBlack} />
        </div>
        <div className={styles.candidateHeader}>
          <p className={styles.name}>
            <span className={styles.order}>{candidatePosition}.</span>
            {candidate.name}
          </p>
          <p className={styles.additionalInfo}>{type}</p>
        </div>
        <div
          onClick={() => handleShortlistedChange(candidate.id)}
          className={classNames(styles.heart)}
        >
          <Icon
            icon={
              candidatesList.find((cand) => cand.id === candidate.id)?.isShortlisted
                ? IconType.HeartFull
                : IconType.HeartOutline
            }
          />
        </div>
        <div
          className={classNames(styles.changeCandidateBtn, styles.nextCandidate)}
          onClick={handleNextCandidate}
        >
          <Icon icon={IconType.ArrowDownBlack} />
        </div>
      </div>
      <div className={styles.aiInfo}>
        <div className={styles.similarity}>
          <Icon icon={IconType.Similarity} />
          <p>
            <span>Similarity: </span>
            {candidate.score} %
          </p>
        </div>
        <div className={styles.likeButtons}>
          <button className={classNames(styles.likeBtn, styles.dislike)}>
            <Icon icon={IconType.Like} />
          </button>
          <button className={classNames(styles.likeBtn, styles.like)}>
            <Icon icon={IconType.Like} />
          </button>
        </div>
      </div>
      {/* <button className={styles.cvBtn}>CV Preview</button> */}
      <div className={styles.candidateInfo}>
        {candidate.noteContent != null &&
        (<div>
          <span className={styles.headerText}>Notes</span>
          <div className={styles.notes} dangerouslySetInnerHTML={{ __html: candidate.noteContent }}/>
        </div>)}

        <div className={styles.likeButtons}>
          
        </div>
      </div>
      <span className={styles.headerText}>Curriculum Vitae</span>
      <div className={styles.resume} dangerouslySetInnerHTML={{ __html: candidate.resume }} />
    </div>
  );
};

export default CandidateDetails;
