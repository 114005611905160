import { useState } from 'react';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import { translate } from 'src/contexts/Language';
import type { SettingsPageType } from 'src/pages/SettingsPage/SettingsPage.def';
import styles from './SettingsPageTemplate.module.scss';

const SettingsPageTemplate = () => {
  const [settingsPageMode, setSettingsPageMode] = useState<SettingsPageType>('EMAIL_CREATOR');

  return (
    <main className={styles.container}>
      <AppHeader currentPage='REQUISITIONS' />
      <section className={styles.mainCard}>
        <h1>{translate('settings')}</h1>
        <ul className={styles.options}>
          {/* <li
            onClick={() => {
              setSettingsPageMode('LANGUAGE_AND_REGION');
            }}
            className={settingsPageMode === 'LANGUAGE_AND_REGION' ? styles.activeOption : ''}
          >
            {translate('languageAndRegion')}
          </li>
          <li
            onClick={() => {
              setSettingsPageMode('AUTOLOGOUT_SETTINGS');
            }}
            className={settingsPageMode === 'AUTOLOGOUT_SETTINGS' ? styles.activeOption : ''}
          >
            {translate('autologoutSettings')}
          </li> */}
        </ul>
      </section>
    </main>
  );
};

export default SettingsPageTemplate;
