import styles from './MatchingKeywords.module.scss';
import { useState } from 'react';

interface IMatchingKeywordsTemplateProps {
  keywords: string;
  setKeywords: (keywords: string) => void;
}

const MatchingKeywords = ({
  keywords,
  setKeywords,
}: IMatchingKeywordsTemplateProps) => {
  const [tempKeywords, setTempKeywords] = useState<string>(keywords);

  return (
    <div className={styles.matchingKeywordsContainer}>
      <div className={styles.matchingKeywordsHeader}>
        <div className={styles.matchingKeywordsTitle}>
          <h2>Keywords</h2>
          <p>Write most important keywords of this job offer</p>
        </div>
      </div>
      <textarea
        className={styles.textarea}
        defaultValue={keywords}
        placeholder='Write most important keywords here...'
        onChange={(e) => {
          setTempKeywords(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter')
          {
            e.preventDefault();
            setKeywords(tempKeywords);
            
          }
        }}
      ></textarea>
    </div>
  );
};

export default MatchingKeywords;
