import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import type { RootState } from 'src/store/store';

const initialState: IRequisition[] | null = [];

export const requisitionsSlice = createSlice({
  name: 'requisitions',
  initialState,
  reducers: {
    setRequisitions: (state, action: PayloadAction<IRequisition[]>) => action.payload,
    toggleRequisitionCard: (state, action: PayloadAction<number>) =>
      state.map((req) =>
        req.jobOrderId === action.payload ? { ...req, expanded: !req.expanded } : { ...req },
      ),
  },
});

export const { setRequisitions, toggleRequisitionCard } =
  requisitionsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getRequisitions = (state: RootState) => state.requisitions;

export default requisitionsSlice.reducer;
