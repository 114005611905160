import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import InfoCard from 'src/components/molecules/InfoCard/InfoCard';
import Pagination from 'src/components/molecules/Pagination/Pagination';
import AIMatchingRequisitionsList from 'src/components/organisms/AIMatchingRequisitionsList/AIMatchingRequisitionsList';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import type { IRequisitionsPageData } from 'src/pages/RequisitionsPage/Requisitions.def';
import { useAppSelector } from 'src/store/reduxHooks';
import styles from './AIMatchingPageTemplate.module.scss';

const AIMatchingPageTemplate = ({ infoCard, isPageLoading }: IRequisitionsPageData) => {
  const requisitionsList = useAppSelector((state) => state.requisitions);

  return (
    <main className={styles.container}>
      <AppHeader currentPage='AI_MATCHING' />
      {!infoCard && <LoadingSpinner />}
      {infoCard && (
        <>
          <InfoCard {...infoCard} />
          <AIMatchingRequisitionsList requisitionsList={requisitionsList} />
          {isPageLoading && <LoadingSpinner />}
          <Pagination />
        </>
      )}
    </main>
  );
};

export default AIMatchingPageTemplate;
